$.validator.setDefaults({
	highlight: function(element) {
		$(element).closest('.form-group').removeClass('has-success');
		$(element).closest('.form-group').addClass('has-error');
	},
	unhighlight: function(element) {
		$(element).closest('.form-group').removeClass('has-error');
		$(element).closest('.form-group').addClass('has-success');
	},
	errorPlacement: function(error, element) {
		$(error).css('color','#a94442').css('font-style','italic').css('font-size','0.8em').css('display','block');
        $(element).closest('.form-group').find('.help-block.with-errors').append(error);
        // $(element).closest('.form-group').find('label').append(error);
	},
	success: function(element) {
		$(element).closest('.form-group').removeClass('has-error');
		$(element).closest('.form-group').addClass('has-success');
	}
});


$.validator.addMethod("validatordateddmmyyyy", function(value, element) {
  return this.optional( element ) || /^[0-9]{1,2}[\/][0-9]{1,2}[\/][0-9]{4}$/.test( value );
}, 'Gelieve het juiste formaat te gebruiken');

$.validator.addMethod("validatormobile", function(value, element) {
  return this.optional( element ) || /^[0][0-9]{3}[\/][0-9]{2}[\.][0-9]{2}[\.][0-9]{2}$/.test( value );
}, 'Gelieve het juiste formaat te gebruiken');

$.validator.addMethod("validatorphone", function(value, element) {
  return this.optional( element ) || /^[0][0-9]{2}[\/][0-9]{2}[\.][0-9]{2}[\.][0-9]{2}$/.test( value );
}, 'Gelieve het juiste formaat te gebruiken');



$.validator.addClassRules("validator-digits", {
  digits: true,
});
$.validator.addClassRules("validator-decimal", {
  number: true,
});
$.validator.addClassRules("validator-equal-password", {
  equalTo: "#password",
});
$.validator.addClassRules("validator-date-ddmmyyyy", {
  validatordateddmmyyyy: true,
});
$.validator.addClassRules("validator-mobile", {
  validatormobile: true,
});
$.validator.addClassRules("validator-phone", {
  validatorphone: true,
});
$.validator.addClassRules("validator-1-of", {
  require_from_group: [1,'.validator-1-of'],
});
