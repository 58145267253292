	
$(document).ready(function()
{

	ajshtml_load_functions(document)
	$.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
	});

	// clientFilter
	$('#clientTableFilter').change(function(){
		this.form.submit();
	});

    //confirm
	$(document).on('click','.confirm',function(e){
		if(!confirm($(this).data('question'))){
			e.preventDefault();
		}
	});

	// datepicker.js
	// Dutch
    $(document).on('click','.datepicker-trigger',function(event) {

    	var element = $(this).data('datepicker-field');

    	// get max date
    	var max = false;
    	var datemax = $(element).prop('max');
    	if( datemax ) {
    		max = datemax.split('-')
    		max[1] -= 1;
    		var maxYear = max[0];
    	}
    	// min date
    	var datemin = $(element).prop('min');
    	var min = false;
    	if( datemin )	{
    		min = datemin.split('-')
    		min[1] -= 1
    		var minYear = min[0];
    	}
    	// select selectYears
    	var selectYears = 50;
    	if(min && max) selectYears = maxYear - minYear;


	    var $input = $(element).pickadate({
	                format: 'dd/mm/yyyy',
	                formatSubmit: 'dd/mm/yyyy',
	                editable: true,
	                hiddenName: true,
	                selectYears: true,
  					selectMonths: true,
				    monthsFull: [ 'januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december' ],
				    monthsShort: [ 'jan', 'feb', 'maa', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec' ],
				    weekdaysFull: [ 'zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag' ],
				    weekdaysShort: [ 'zo', 'ma', 'di', 'wo', 'do', 'vr', 'za' ],
				    today: 'vandaag',
				    clear: 'verwijderen',
				    close: 'sluiten',
				    firstDay: 1,
				    max: max,
				    min: min,
				    selectYears: selectYears
	    });

    	var picker = $input.pickadate('picker');

        if (picker.get('open')) {
            picker.close();
        } else {
            picker.open();
        }                        
        event.stopPropagation();

    })

    
	// set price admin mode
	$(document).on('change','select[data-prices]',function(){
		setPrice();
	});


   $(document).on('click','.custom-toggle',function(){

      var trigger = $(this);
      var element = $(trigger).data('toggle-element');
      var collapsed = $(element).is(':visible');
      var icon = $(trigger).find('.custom-toggle-icon');

      if(collapsed){
         $(icon).html('<span class="glyphicon glyphicon-plus-sign">')
         $(element).slideUp();         
      }else{
         $(icon).html('<span class="glyphicon glyphicon-minus-sign">')
         $(element).slideDown();    
      }

   });

});


function ajshtml_load_functions(ajshtml_element){

	initializeValidator(ajshtml_element);

	setPrice();

   	$(ajshtml_element).find('.custom-toggle').each(function(){
		var trigger = $(this);
		var element = $(trigger).data('toggle-element');
		var collapsed = $(trigger).data('collapsed');

		$(trigger).css("cursor","pointer");

		if(collapsed){
			 $(trigger).append('<span class="pull-right custom-toggle-icon"><span class="glyphicon glyphicon-minus-sign"></span>')
			 $(element).show();
		}else{
			 $(trigger).append('<span class="pull-right custom-toggle-icon"><span class="glyphicon glyphicon-plus-sign"></span>')
			 $(element).hide();
		}

   	});

	$(ajshtml_element).find('form input, form textarea, form select').each(function(){
		if($(this).prop("required")){

			if($(this).closest('.form-group').find('label').html().indexOf(' *') == -1 ){
				$(this).closest('.form-group').find('label').append('<span style="color:red"> *</span>');
			}
		}
	});

	$(ajshtml_element).find('table.datatable').DataTable({
		"aaSorting": [],
	    "language": {
	        "lengthMenu": "Toon _MENU_ artikels per pagina",
	        "zeroRecords": "Geen artikels gevonden",
	        "info": "pagina _PAGE_ van _PAGES_",
	        "infoEmpty": "Geen artikels gevonden",
	        "infoFiltered": "(van _MAX_ artikels)",
	        "emptyTable" :  'Geen artikels gevonden',
			"info" : "Toont _START_ tot _END_ van _TOTAL_ artikels",
			"lengthMenu" : "Toon _MENU_ items",
			"loadingRecords" : "Bezig met laden...",
			"processing" : "Bezig...",
			"search" : "Zoek:",
			"paginate":{
				"first" : "Eerste",
				'last' : 'Laatste',
				'next' : 'Volgende',
				'previous' : 'Vorige',
			}
	    }
	});

	$(ajshtml_element).find('.selectize').selectize({
		plugins: ['remove_button']
	});

	// inputmasks
	$(ajshtml_element).find('.datemask').inputmask(
		"dd/mm/yyyy",
		{ 
			"placeholder": "dd/mm/yyyy"
		}
	);

	$(ajshtml_element).find('.phonemask').inputmask('999/99.99.99');
	$(ajshtml_element).find('.mobilemask').inputmask('9999/99.99.99');

}

function setPrice(){
	var element = $('select[data-prices]')
	if($(element).length != 0){
		var value = $(element).val();
		var prices = $(element).data('prices');
		$('#price').val( prices[value] );
	}
}

function initializeValidator(element){
	// setting help-block and feedback on every form
	$(element).find('.form .form-group').each(function(){
		$(this).find(':input')
			.wrap('<span></span>')
			.closest('span')
			.after('<div class="help-block with-errors"></div>');
	});

	// $('.form.validator .form-group :input').change(function(){;
	// 	$(this).validate();
	// 	// $(this).closest('.form').validate(this);
	// });

	$(element).find(".validator").submit(function(e){
		e.preventDefault();
		$(this).validate();
		if($(this).valid() && !$(this).hasClass('noSubmit')){
			this.submit() ;
		}
	});

	$(element).find(".validate").click(function(e){
		e.preventDefault();
		var form = $(this).closest('.validator')
		$(form).validate();
		if($(form).valid() && !$(this).hasClass('noSubmit') ){
			form.submit();
		}
	});	

	// validate elements on blur
    $(element).find('.validator').validate({
        onchange: function(element) {
           $(element).valid();
        }
    });

	// validate elements on blur
    $(element).find('.validate_blur').validate({
        onchange: function(element) {
           $(element).valid();
        }
    });
	
}