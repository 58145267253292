$(function(){

	$(document).on('submit','form.ajshtml_form',function(e){
		e.preventDefault();

		var element = $(this);
		var form_html = $(this).html();
		var href = $(element).attr('action');
		var confirm_message = $(this).data('ajshtml_confirm');
		if(confirm_message){
			if(!confirm( confirm_message )){
				return false;
			}
		}

		$.ajax({
			method: "POST",
			url: href,
			data: $(element).serialize(),
			success: function(response){
				var response= $.parseJSON(response);
				response.element = element;
				ajshtml_success_event(response);
				ajshtml_errors(response);
			},
			error: function(xhr, status , error){ alert( error )}
		});

	});

	// $(document).on('focusout','form.ajshtml_focusout :input',function(e){
	// 	var form = $(this).closest('form');
	// 	var element = $(this);
	// 	var href = $(form).attr('action') + '?'+$(this).prop('name')+'='+$(this).val();
	// 	$.ajax({
	// 		method: "POST",
	// 		url: href,
	// 		data: {ajshtml_validate:'true'},
	// 		success: function(response){
	// 			var response= $.parseJSON(response);
	// 			response.element = element;
	// 			ajshtml_errors(response);
	// 			ajshtml_success_event(response);
	// 		},
	// 		error: function(xhr, status , error){ alert( error )}
	// 	});

	// });

	$(document).on('click','.ajshtml_close_message',function(e){

		$(this).closest('.ajshtml_message').fadeOut('slow');
		$(this).closest('tr').fadeOut('slow');

	});

	$(document).on('click','.ajshtml_trigger',function(e){
		e.preventDefault();

		var element = $(this);
		var href = $(this).data('href');
		var confirm_message = $(this).data('ajshtml_confirm');
		if(confirm_message){
			if(!confirm( confirm_message )){
				return false;
			}
		}

		$.ajax({
			method: "GET",
			url: href,
			success: function(response){
				var response= $.parseJSON(response);
				response.element = element;
				ajshtml_errors(response);
				ajshtml_success_event(response);
			},
			error: function(xhr, status , error){ alert( error )}
		});

	});

});

function ajshtml_errors(response){
	$(response.element).find('label').removeClass('ajshtml_error');
	$(response.element).find('.ajshtml_label_info').remove();
	if(response.messages){
		notificate(response)
	}
}

function ajshtml_success_event(response){


	if(response.html){
		if(response.html.prepend){
			$.each(response.html.prepend,function(k,v){
				$(k).prepend(v);
				var prependedElement = $(k).children()[0];
				$(prependedElement).hide().fadeIn('slow');
				ajshtml_load_functions( prependedElement );
			});
		}
		if(response.html.html){
			$.each(response.html.html,function(k,v){
				$(k).html(v).hide().fadeIn('slow');
				ajshtml_load_functions( $(k) );
			});
		}
		if(response.html.remove){
			$.each(response.html.remove,function(k,v){
				$(v).remove();
			});
		}
		if(response.html.fadeOut){
			$.each(response.html.fadeOut,function(k,v){
				$(v).fadeOut();
			});
		};
	}
	if(response.modal){
		$('#ajshtml_modal').modal();
		$('#ajshtml_modal .modal-body').html(response.modal);
		ajshtml_load_functions( $('#ajshtml_modal .modal-body') );
		$("#ajshtml_modal").scrollTop(0);
	}
}

function notificate(response){
	if( $(window).width() > 500  ){
		message_alert = false;
		message_inline = true;
	}else{
		message_alert = true;
		message_inline = true;
	}
	if(response.config){
		if(response.config.messages){
			if(response.config.messages.type){
				if(response.config.messages.type == 'inline'){
					message_inline = true;
					message_alert = false;
				}else if(response.config.messages.type == 'alert'){
					message_alert = true;
					message_inline = false;
				}
			}
		}
	}

	var d = new Date();
	var datetime = d.toLocaleString();

	if(response.messages){
		if( response.messages.errors){
			if(message_alert) {
				var errors_alert = "";
			}
			if(message_inline) {
				var errors_inline = '<div class="ajshtml_message ajshtml_error"><small><div><button class="ajshtml_close_message">X</button>'+ datetime +'</div></small><ul>';
			}
			$(response.messages.errors).each(function(k,value){
				if(message_alert) {
					errors_alert += '- '+value+"\n";
				}
				if(message_inline) {
					errors_inline += '<li>'+value+"</li>";
				}
			});
			if(message_alert) {
				alert(errors_alert);
			}
			if(message_inline) {
				errors_inline += '</ul></div>';
				ajshtml_set_inline_message(response, errors_inline);
			}
		}
		if( response.messages.errors_by_fieldname){
			console.log('test',response.messages.errors_by_fieldname)
			$.each(response.messages.errors_by_fieldname,function(name,value){
				$(':input[name="'+name+'"]').addClass('ajshtml_error')
				label = ajshtml_get_label(name);
				html = '<div class="ajshtml_label_message ajshtml_error"><ul>';
				$.each(value,function(k,v){
					html += '<li>'+v+'</li>';
				});
				html += '</ul></div>';
				ajshtml_set_label_message(label,html)
			});
		}
		
		if(response.messages.success){
			if(message_alert) {
				var success_alert = "";
			}
			if(message_inline) {
				var success_inline = '<div class="ajshtml_message ajshtml_success"><small><div><button class="ajshtml_close_message">X</button>'+ datetime +'</div></small><ul>';
			}
			$(response.messages.success).each(function(k,value){
				if(message_alert) {
					success_alert += '- '+value+"\n";
				}
				if(message_inline) {
					success_inline += '<li>'+value+"</li>";
				}
			});
			if(message_alert) {
				alert(success_alert);
			}
			if(message_inline) {
				success_inline += '</ul></div>';
				ajshtml_set_inline_message(response, success_inline);

			}
		}
	}
}

function ajshtml_set_inline_message(response,message){

	if(response.config && response.config.container){
		container = response.config.container;
	}else{
		container = response.element;
	}

	if( $(container).is('tr') ){
		$(container).before('<tr><td colspan=1000>'+message+'</td></tr>');
		$(container).prev().hide().fadeIn('slow');
	}else{
		$(container).before(message);
		$(container).prev().hide().fadeIn('slow');
	}

}

function ajshtml_get_label(name){

	var label = $('label[for="'+name+'"]');

	if(label.length <= 0) {
	    var parentElem = $('#'+name).parent();
	    if(parentElem.length <= 0){
	    	return false;
	    }
	    var parentTagName = parentElem.get(0).tagName.toLowerCase();

	    if(parentTagName == "label") {
	        label = parentElem;
	    }
	}
	return label;
}
function ajshtml_get_label_input(label){
	if($(label).find(':input').length > 0 ){
		return $(label).find(':input');
	}
	return $('#'+ $(label).prop('for'));
}
function ajshtml_set_label_message(label,html){
	if($(label).find('.ajshtml_label_message').length > 0){
		$(label).find('.ajshtml_label_message').remove();
	}
	$(label).append('<span class="ajshtml_label_info"> <button type="button">?</span>'+html+'</i>').addClass('ajshtml_error');	
}

$(document).on('click','.ajshtml_label_info',function(){

	$(this).find('.ajshtml_label_message').toggle();

});
