$(function(){
	// sowieso uitvoeren
	setBodyPadding();

	// resizing window: bodyPadding
	$(window).resize(function(){
		setBodyPadding();
	});

	// bodypadding toepassen na klik ok inklapmenu met halve seconde vertraging om juiste hoogte te hebben.
	// $('.toggleNavbar').click(function(){
	// 	setTimeout(	setBodyPadding,	500);
	// });

	// menu laten verdwijnen bij scrollDown. Uncomment voor enkel mobiel
    var previousScroll = 0;
    $(window).scroll(function(){
	    // if($(window).width() <= "768" ){
			var currentScroll = $(this).scrollTop();
			if (currentScroll > previousScroll+5){
			   $('.navbar').fadeOut();
			} else if( currentScroll < previousScroll-5 || currentScroll == 0 ) {
			  $('.navbar').fadeIn();
			}
		// }else{
			// $('.navbar').fadeIn();
		// }
		previousScroll = currentScroll;
   	});
});

// padding-top aanpassen om steeds begin van pagina onder de navbar te houden
function setBodyPadding(){
	var navbarHeight =	$('nav .container, nav .container-fluid').outerHeight();
	var bodyPadding = (navbarHeight+10)+'px';
	$('body').animate({'padding-top':bodyPadding});
}