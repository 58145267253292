function getUrlLess(url){
	// trim variables
	var array = url.split('?');
	var urlTrimVariables = $(array).get(0);

	// split parts by "/" and remove numeric parts
	array = urlTrimVariables.split('/');
	var result = ''
	$.each(array,function(key, value){
		if( !$.isNumeric(value)){
			result = result+value+'/'
		}
	});
	result = result.substring(0,result.length-1)
	return result;
}

$(function(){
   	// goBack

   	// get url's
 	var url = window.location.href
 	var goBackUrl = $.cookie('goBackUrl');

 	// get lastUrl
 	if( $.cookie('lastUrl') ){
   	var lastUrl = $.cookie('lastUrl');
 	}else{
 		var lasturl = url;
 	}

   	if( $.cookie('goBackUrl') ){

   		// url's without parameters
   		var urlLess = getUrlLess(url);
   		var lastUrlLess = getUrlLess(lastUrl);
   		var goBackUrlLess = getUrlLess(goBackUrl);

   		if( lastUrlLess != urlLess){
   			$.cookie('goBackUrl', lastUrl ,{ path: '/' });
   		}
   	}else{
   		$.cookie('goBackUrl', url ,{ path: '/' });	
   	}
   	$.cookie('lastUrl', url ,{ path: '/' });

   $('.goBack').click(function(){
        window.location.href = $.cookie('goBackUrl');
   });
})
